<template>
	<div class="council">
		<SubHeader title="Team Ranking" subtitle="対戦表＋ポイント表" />

		<div class="container">
			<div class="row justify-content-center">
				<div class="col-lg-12">
					<ul class="nav nav-pills" id="myTab" role="tablist">
						<li class="nav-item">
							<a
								class="nav-link active"
								data-toggle="tab"
								href="#a"
								@click="
									group_id = 1;
									getTeamRanking();
								"
							>
								<span class="group">A</span>ディビジョン
							</a>
						</li>
						<li class="nav-item">
							<a
								class="nav-link"
								data-toggle="tab"
								href="#b"
								@click="
									group_id = 2;
									getTeamRanking();
								"
							>
								<span class="group">B</span>ディビジョン
							</a>
						</li>
						<li class="nav-item">
							<a
								class="nav-link"
								data-toggle="tab"
								href="#c"
								@click="
									group_id = 3;
									getTeamRanking();
								"
							>
								<span class="group">C</span>ディビジョン
							</a>
						</li>
						<li class="nav-item">
							<a
								class="nav-link"
								data-toggle="tab"
								href="#d"
								@click="
									group_id = 4;
									getTeamRanking();
								"
							>
								<span class="group">D</span>ディビジョン
							</a>
						</li>
						<li class="nav-item">
							<a
								class="nav-link"
								data-toggle="tab"
								href="#e"
								@click="
									group_id = 5;
									getTeamRanking();
								"
							>
								<span class="group">E</span>ディビジョン
							</a>
						</li>
						<!--<li class="nav-item">
							<a
								class="nav-link"
								data-toggle="tab"
								href="#f"
								@click="
									group_id = 6;
									getTeamRanking();
								"
							>
								<span class="group">F</span>ディビジョン
							</a>
						</li>-->
					</ul>
				</div>
				<div class="col-lg-12">
					<div class="alert alert-primary caution mt-4" role="alert">
						対戦結果のチーム名をクリックするとリザルトが閲覧できます
					</div>
				</div>
			</div>
		</div>

		<div class="table-responsive">
			<table
				class="table table-bordered table-striped text-center my-5"
				v-if="!isLoading"
			>
				<caption>
					※この表はポイント（pts）のみ基準にしているため、 同点の場合の順位は
					<a href="/pdf/odo_rule.pdf" target="_blank">競技規約</a
					>を確認してください
				</caption>
				<thead class="thead-dark">
					<tr>
						<th scope="col">Team</th>
						<th scope="col">pts</th>
						<template v-for="(match_day, index) in match_days">
							<th scope="col" :key="'match_day' + match_day">
								<span class="badge badge-pill badge-light">
									{{ Number(index) + 1 }}
								</span>
								<br />
								{{ match_day }}
							</th>
						</template>
					</tr>
				</thead>
				<tbody>
					<template v-for="team in teams">
						<tr :key="'team_upper' + team.id">
							<th class="team_name">{{ team.name }}</th>
							<td class="pts">{{ team.pts }}</td>
							<template v-for="match_day in match_days">
								<td :key="'match' + match_day">
									<div v-if="team.match[match_day] != undefined">
										<span
											:class="
												'badge home_away ' +
												team.match[match_day].home_away.toLowerCase()
											"
										>
											{{ team.match[match_day].home_away }}
										</span>
										<span class="venue_name"
											>@{{ team.match[match_day].venue }}</span
										>
										<br />
										<span class="vs_team_name">
											<span class="badge badge-light">vs</span>
											<a
												href="javascript:void(0);"
												v-if="team.match[match_day].status == 'finish'"
												@click="openResult(team.match[match_day].result_id)"
												>{{ team.match[match_day].vs }}</a
											>
											<span v-else>{{ team.match[match_day].vs }}</span>
										</span>
										<br />
										<div v-if="team.match[match_day].status != 'still'">
											<i
												class="far fa-circle"
												style="color: #e91e63"
												v-if="team.match[match_day].win"
											></i>
											<i class="fas fa-times" style="color: #999" v-else></i>
											（{{ team.match[match_day].win_lose }}）
										</div>
									</div>
								</td>
							</template>
						</tr>
					</template>
				</tbody>
			</table>
		</div>

		<modal
			class="result"
			name="result"
			adaptive="true"
			width="100%"
			height="auto"
			maxWidth="1000"
			scrollable="true"
		>
			<div class="row">
				<div class="col-8 offset-2">
					<h2>RESULT</h2>
				</div>
				<div class="col-2 text-right">
					<a class="btn">
						<i class="fas fa-window-close" @click="$modal.hide('result')"></i>
					</a>
				</div>
				<div class="col-12">
					<div class="row mb-4">
						<div class="col-6 home_team_name">
							<h3>
								<span>home team</span><br />{{ result.home_team_name }}（{{
									result.win_team_id == result.home_team_id
										? result.win_team_count
										: result.lose_team_count
								}}）
							</h3>
						</div>
						<div class="col-6 away_team_name">
							<h3>
								<span>away team</span><br />{{ result.away_team_name }}（{{
									result.win_team_id == result.home_team_id
										? result.lose_team_count
										: result.win_team_count
								}}）
							</h3>
						</div>
					</div>
					<div class="row">
						<div class="col-lg-6">
							<table class="table table-bordered award">
								<tbody>
									<tr>
										<th>180's</th>
										<td>
											<template v-for="player in ton80_players">
												<span v-if="player != null" :key="player">{{
													players["id" + player]
												}}</span></template
											>&nbsp;
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="col-lg-6">
							<table class="table table-bordered award">
								<tbody>
									<tr>
										<th>HighOff</th>
										<td>
											<span v-if="result.highoff_player_id != null">
												{{ players["id" + result.highoff_player_id]
												}}<span class="badge home_away home">{{
													result.highoff_score
												}}</span>
											</span>
											&nbsp;
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div v-for="(game, i) in result_detail.game" :key="i">
						<h4>
							{{ games[i] }}
						</h4>
						<div class="row">
							<!-- HOME -->
							<div class="result col-6">
								<table class="members table table-bordered">
									<thead>
										<tr>
											<th scope="col" class="text-nowrap">Name</th>
											<th scope="col" class="text-nowrap" style="width: 50px">
												Ton
											</th>
										</tr>
									</thead>
									<tbody>
										<template v-for="(player, index) in game.home">
											<tr v-if="index.match(/player/)" :key="index">
												<td scope="row" class="text-nowrap">
													<span v-if="player.id != null">{{
														players["id" + player.id]
													}}</span>
													<span v-else>{{ player.name }}</span>
												</td>
												<td class="text-center">
													<span v-if="player != null">{{ player.ton }}</span>
												</td>
											</tr>
										</template>
										<tr></tr>
									</tbody>
								</table>
								<table class="table table-bordered leg">
									<tbody>
										<tr>
											<td class="text-center">{{ game.home.leg1 }}&nbsp;</td>
										</tr>
									</tbody>
								</table>
								<table class="table table-bordered leg" v-if="i != 'game1'">
									<tbody>
										<tr>
											<td class="text-center">{{ game.home.leg2 }}&nbsp;</td>
										</tr>
									</tbody>
								</table>
								<table class="table table-bordered leg" v-if="i != 'game1'">
									<tbody>
										<tr>
											<td class="text-center">{{ game.home.leg3 }}&nbsp;</td>
										</tr>
									</tbody>
								</table>
							</div>
							<!-- AWAY -->
							<div class="result col-6">
								<table class="members table table-bordered">
									<thead>
										<tr>
											<th scope="col" class="text-nowrap">Name</th>
											<th scope="col" class="text-nowrap" style="width: 50px">
												Ton
											</th>
										</tr>
									</thead>
									<tbody>
										<template v-for="(player, index) in game.away">
											<tr v-if="index.match(/player/)" :key="index">
												<td scope="row" class="text-nowrap">
													<span v-if="player.id != null">{{
														players["id" + player.id]
													}}</span>
													<span v-else>{{ player.name }}</span>
												</td>
												<td class="text-center">
													<span v-if="player != null">{{ player.ton }}</span>
												</td>
											</tr>
										</template>
										<tr></tr>
									</tbody>
								</table>

								<table class="table table-bordered leg">
									<tbody>
										<tr>
											<td class="text-center">{{ game.away.leg1 }}&nbsp;</td>
										</tr>
									</tbody>
								</table>
								<table class="table table-bordered leg" v-if="i != 'game1'">
									<tbody>
										<tr>
											<td class="text-center">{{ game.away.leg2 }}&nbsp;</td>
										</tr>
									</tbody>
								</table>
								<table class="table table-bordered leg" v-if="i != 'game1'">
									<tbody>
										<tr>
											<td class="text-center">{{ game.away.leg3 }}&nbsp;</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div class="text-center d-lg-none d-md-none">
						<a class="btn btn-contact mb-3" @click="$modal.hide('result')">
							<i class="fas fa-window-close"></i> 閉じる
						</a>
					</div>
				</div>
			</div>
		</modal>
	</div>
</template>

<script>
// @ is an alias to /src
import Vue from "vue";
import SubHeader from "@/components/common/SubHeader.vue";
//import Schedule from "@/components/common/Schedule.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import VModal from "vue-js-modal";
Vue.use(Loading);
Vue.use(VModal);

export default {
	name: "schedule",
	components: {
		SubHeader,
	},
	data() {
		return {
			isLoading: true,
			teams: [],
			match_days: [],
			group_id: null,
			// リザルト
			result: {},
			result_detail: {},
			ton80_players: {},
			players: {},
			games: {
				game1: "Team Game 1001",
				game2: "D-1 501",
				game3: "D-2 501",
				game4: "S-1 501",
				game5: "S-2 501",
				game6: "S-3 501",
				game7: "S-4 501",
			},
		};
	},
	mounted: function () {
		this.getTeamRanking();
	},
	methods: {
		getTeamRanking: function () {
			this.isLoading = true;
			let loader = this.$loading.show({
				// Optional parameters
				container: this.fullPage ? null : this.$refs.formContainer,
				canCancel: false,
				color: this.$store.state.primary_color,
				backgroundColor: "#666",
			});
			let params = {
				group_id: this.group_id,
			};
			this.axios
				.get("https://admin.darts-l.com/api/odo/team_ranking", {
					params: params,
				})
				.then((res) => {
					//console.log("res:" + JSON.stringify(res.data.teams));
					this.match_days = res.data.match_days;
					this.teams = res.data.teams;
					// simulate AJAX
					setTimeout(() => {
						loader.hide();
						this.isLoading = false;
					}, 500);
				});
		},
		openResult(result_id) {
			this.isLoading = true;
			let loader = this.$loading.show({
				// Optional parameters
				container: this.fullPage ? null : this.$refs.formContainer,
				canCancel: false,
				color: this.$store.state.primary_color,
				backgroundColor: "#666",
			});
			this.axios
				.get("https://admin.darts-l.com/api/odo/result/" + result_id)
				.then((res) => {
					//console.log("res:" + JSON.stringify(res.data));
					this.result = res.data.result;
					this.result_detail = res.data.detail;
					this.ton80_players = res.data.ton80_players;
					this.players = res.data.players;
					this.$modal.show("result");
					// simulate AJAX
					setTimeout(() => {
						loader.hide();
						this.isLoading = false;
					}, 500);
				});
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container {
	padding: 4rem 1rem 0 1rem;
	text-align: left;
}
.caution {
	color: #333;
	background-color: #e6ee9c;
	border-color: #e6ee9c;
}

.table-bordered {
	border: 1px solid #999;
	td,
	th {
		border: 1px solid #999;
		vertical-align: middle;
	}
}
table caption a {
	text-decoration: underline;
}
.team_name {
	white-space: nowrap;
	font-size: 1rem;
	font-weight: bold;
}
.pts {
	font-size: 1.2rem;
	color: #000;
	background-color: $primary-color;
}
span.venue_name {
	color: #666;
	white-space: nowrap;
	font-size: 0.7rem;
	font-weight: normal;
}
span.vs_team_name {
	white-space: nowrap;
	font-size: 1rem;
	font-weight: bold;
}
span.home_away {
	color: #000;
	font-size: 0.6rem;
	font-weight: normal;
	&.home {
		background-color: $secondary-color;
	}
	&.away {
		color: $secondary-color;
		background-color: #333;
	}
}

.home_team_mame {
	font-size: 1.8rem;
	padding-left: 0.8rem;
	text-align: left;
}
.away_team_name {
	font-size: 1.8rem;
	padding-right: 0.8rem;
	text-align: right;
}
table {
	&.members {
		width: 60%;
		margin-left: 0.8rem;
	}
	&.leg {
		width: 30px;
		margin-left: 0.8rem;
		margin-right: 0;
		float: left;
	}
	&.award {
		width: 90%;
		margin-left: 0.8rem;
		margin-right: 0;
		th {
			width: 50px;
		}
	}
	&.table {
		td,
		th {
			font-size: 0.7rem;
			padding: 0.4rem;
		}
		thead {
			background-color: #eee;
		}
	}
}
h2 {
	padding: 0.8rem;
	text-align: center;
}
h3 {
	margin: 0 1rem;
	span {
		font-size: 0.8rem;
		color: #666;
	}
}
h4 {
	font-size: 1rem;
	padding: 0.4rem 0.6rem;
	margin: 1.6rem 0.8rem 0.8rem 0.8rem;
	border-left: solid 5px $primary-color;
	background-color: #333;
	color: #fff;
}
</style>
